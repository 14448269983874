export class CommonConst {
  static SET_LOADING = 'common/setLoading';
  static THEME = 'common/theme';
  static IS_LOADING = 'common/isLoading';
  static TOGGLE_THEME = 'common/toggleTheme';
  static TOGGLE_DRAWER = 'common/toggleDrawer';
  static DRAWER = 'common/drawer';

  static SET_LOADING_WITH_KEY = 'common/setLoadingWithKey';
  static IS_LOADING_WITH_KEY = 'common/isLoadingWithKey';
}

export class AuthConst {
  static SET_USER = 'auth/setUser';
  static CLEAR_USER = 'auth/clearUser';
  static SET_PERMISSION = 'auth/setPermissions';
  static IS_AUTHENTICATED = 'auth/isAuthenticated';
}

export class LinkConst {
  static ADD_DOWNLOAD_QUEUE = 'link/adddownloadqueue';
  static ADD_PARSE_QUEUE = 'link/addparsequeue';
}

export class GridConst {
  static PK_FIELD = 'grid/pkField';
  static OPTIONS = 'grid/options';
  static SET_DATA = 'grid/setData';
  static SET_PRESET = 'grid/setPreset';
  static UPSERT_ITEM = 'grid/upsertItem';
  static HEADERS = 'grid/headers';
  static ITEMS = 'grid/items';
  static COUNT = 'grid/count';
  static DEFAULT_ACTIONS = 'grid/defaultActions';
  static CUSTOM_GRID_BUTTONS = 'grid/customGridButtons';
  static CUSTOM_GRID_PROCESS_BUTTONS = 'grid/customGridProcessButtons';
  static ENDPOINTS = 'grid/endpoints';
  static FORM_DATA = 'grid/formData';
  static SET_CONFIG = 'grid/setConfig';
  static RESET_ITEM = 'grid/resetItem';
  static DEFAULT_FORM_DATA = 'grid/defaultFormData';
  static PRESET = 'grid/preset';
  static EDIT_ITEM = 'grid/editItem';
  static DELETE_ITEM = 'grid/deleteItem';
  static DATA_FIELD = 'grid/dataField';
  static IS_EDIT_MODE = 'grid/isEditMode';
  static SET_FILTER_PRESET = 'grid/setFilterPreset';
  static FILTER_PRESET = 'grid/filterPreset';
}

export class ReportConst {
  static SET_DATA = 'report/setData';
  static SET_PRESET = 'report/setPreset';
}
