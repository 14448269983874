import AuthController from '@/controller/auth.controller';
import GridController from '@/controller/grid.controller';
import LinkController from '@/controller/link.controller';
import LinkSettingController from '@/controller/linkSetting.controller';
import ReportController from '@/controller/report.controller';
import RoleController from '@/controller/role.controller';
import ArchiveTaskController from '@/controller/archiveTask.controller';

const GlobalController = {
  install(Vue) {
    Vue.prototype.$gridController = new GridController(
      Vue.prototype.$paasService
    );
    Vue.prototype.$authController = new AuthController(
      Vue.prototype.$paasService
    );
    Vue.prototype.$roleController = new RoleController(
      Vue.prototype.$paasService
    );
    Vue.prototype.$reportController = new ReportController(
      Vue.prototype.$paasService
    );
    Vue.prototype.$linkController = new LinkController(
      Vue.prototype.$paasService
    );
    Vue.prototype.$linkSettingController = new LinkSettingController(
      Vue.prototype.$paasService
    );
    Vue.prototype.$archiveTaskController = new ArchiveTaskController(
      Vue.prototype.$paasService
    );
  },
};

export default GlobalController;
