import BaseController from '@/common/base.controller';
import { CommonConst } from '@/common/store.constants';
import store from '@/store';

class ArchiveTaskController extends BaseController {
  constructor(httpService) {
    super(httpService);
  }

  async addArchive(path, payload) {
    return new Promise((resolve, reject) => {
      store.commit(CommonConst.SET_LOADING, true, { root: true });

      this.insert(path, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          store.commit(CommonConst.SET_LOADING, false, { root: true });
        });
    });
  }
}

export default ArchiveTaskController;
